import txtSrc from "assets/text.json";
import figImg from "assets/images/fig_01.svg";
import Divider from 'comps/Divider/Divider';
import './About.css';
const About =  ()=>{
    return (
      <div className="container-small flex-col-center" dir={window.lang=="heb"?"rtl":"ltr"}>
        <img src={figImg} width="300" alt="about"/>
        <h2 className="text-white">
            {txtSrc.about.title[window.lang]}
        </h2>
        <div className='paragraph'>
            {txtSrc.about.p1[window.lang]}<br/><br/>
            {txtSrc.about.p2[window.lang]}<br/><br/>
            {txtSrc.about.p3[window.lang]}<br/><br/>
            {txtSrc.about.p4[window.lang]}<br/><br/>
            {txtSrc.about.p5[window.lang]}
       </div>
       <h2 className="text-white">
            {txtSrc.about.call[window.lang]}
       </h2>
       <Divider/>
      </div>
    );
}

export default About;
