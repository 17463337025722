// imports
import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import txtSrc from "assets/text.json";
import Divider from 'comps/Divider/Divider';
import figImg from "assets/images/fig_03.svg";
import './Contact.css';

// global inits
const sendMail = httpsCallable(getFunctions(), 'sendMail');

// main
const Contact = ()=>{
    // comps
    const ContactForm = ()=>{
        // inits
        const [wait, setWait] = useState(false);
        const [suc, setSuc] = useState(false);
        const [err, setErr] = useState(false);
        
        // funcs
        const doSend = async(e)=>{
            const isEmail = (email) => {
                return String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            }
            try{
                const name = document.getElementById('name');
                const email = document.getElementById('email');
                const msg = document.getElementById('msg');
                if(name.value===''){
                    name.classList.add('warn');
                    name.focus();
                    return;
                }
                else if(!isEmail(email.value)){
                    email.classList.add('warn');
                    email.focus();
                    return;
                }
                else if(msg.value===''){
                    msg.classList.add('warn');
                    msg.focus();
                    return;
                }
                setErr(false);  
                setSuc(false);
                setWait(true);
                await sendMail({
                    name: name.value,
                    email: email.value,
                    msg: msg.value
                });
                window.dataLayer.push({'event': 'lead-form-submit'});
                name.value = '';
                email.value = '';
                msg.value = '';
                setSuc(true);
            }
            catch(e){
                console.log(e.message);
                setErr(true);
            }
            setWait(false);
        }
        const handleChange = (e)=>{
            e.target.classList.remove('warn');
        }

        // comps
        const Suc = ()=>{
            return(
                <div className='suc-msg'>
                    {txtSrc.contact.sent[window.lang]}
                </div>
            )
        }
        const Err = ()=>{
            return(
                <div className='err-msg'>
                    We couldn't send the email :(<br/>
                    Can you try again?
                </div>
            )
        }
        const Wait = ()=>{
            return(
                <div className='load'>
                    <img src='/media/images/general/load.svg' alt="load"/>
                </div>
            )
        }

        // render
        return(
            <div className='form flex-col-center'>
                <div className='flex-col-center'>
                    <div className='flex-row-center full-width'>
                        <input type='text' id='name' placeholder={txtSrc.contact.name[window.lang]} onChange={handleChange} disabled={wait}/>
                        <input type='email' id='email' placeholder={txtSrc.contact.email[window.lang]} onChange={handleChange} disabled={wait}/>
                    </div>
                    <textarea id='msg' rows="10" placeholder={txtSrc.contact.msg[window.lang]} onChange={handleChange} disabled={wait}/>
                    <button id='send' className='link bc-4 c-1' onClick={doSend} disabled={wait}>
                        {txtSrc.contact.send[window.lang]}
                    </button>
                </div>
                {wait?<Wait/>:""}
                {suc?<Suc/>:""}
                {err?<Err/>:""}
            </div>
        )
    }
    // render
    return (
      <div className="container-small flex-col-center" dir={window.lang=="heb"?"rtl":"ltr"}>
        <img src={figImg} width="200" alt="contact"/>
        <div className='contact-email'>
            hi@dbanimations.com
        </div>
        <ContactForm />
        <Divider/>
      </div>
    )
}

export default Contact;
