import './Services.css';
import { useNavigate } from "react-router-dom";
import txtSrc from "assets/text.json";
import Divider from 'comps/Divider/Divider';
import figBImg from "assets/images/fig_02.svg";

const icons = [
    "concept",
    "script",
    "visual",
    "anim",
    "voice",
    "edit",
    "star"   
]
const iconsPath = "/media/images/icons/";
const iconsImages = []
icons.forEach(icon => {
    iconsImages.push(iconsPath+icon+".svg")
});

const Services =  ()=>{
    const navigate = useNavigate();
    return (
      <div className="container-small flex-col c-1 services" dir={window.lang=="heb"?"rtl":"ltr"}>
        <center>
            <img src={figBImg} alt="services" width="300"/>
            <h2 style={{margin:"0px"}}>
                {txtSrc.services.sectionA.title[window.lang]}
            </h2>
        </center>
        <div className='margin-10'/> 
        <div className='font-20'>
            {txtSrc.services.sectionA.textA[window.lang]}<br/>
            <div className='margin-5'/>
            {txtSrc.services.sectionA.textB[window.lang]}<br/>
            <div className='margin-5'/>
            {txtSrc.services.sectionA.textC[window.lang]}
        </div>
        <div className='margin-10'/> 
        <h3 style={{margin:"0px"}}>
            {txtSrc.services.sectionB.titleB[window.lang]}
        </h3>
        <div className='margin-5'/> 
        <p style={{margin:"0px"}}>
            {txtSrc.services.sectionB.text[window.lang]}
        </p>
        <ol style={{margin:"0px"}}>
            <li>
                <img src={iconsImages[0]} width="50"/>
                <strong>
                    {txtSrc.services.sectionB.subA.title[window.lang]}
                </strong><br/>
                {txtSrc.services.sectionB.subA.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[1]} width="50"/>
              <strong>{txtSrc.services.sectionB.subB.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionB.subB.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[2]} width="50"/>
            <strong>{txtSrc.services.sectionB.subC.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionB.subC.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[3]} width="50"/>
            <strong>{txtSrc.services.sectionB.subD.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionB.subD.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[4]} width="50"/>
            <strong>{txtSrc.services.sectionB.subE.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionB.subE.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[5]} width="50"/>
            <strong>{txtSrc.services.sectionB.subF.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionB.subF.text[window.lang]}
            </li>
        </ol>
        <div className='margin-10'/>
        <center>
            <h2 style={{margin:"0px"}}>{txtSrc.services.sectionC.title[window.lang]}</h2>
        </center>
        <ol style={{margin:"0px"}}>
            <li>
            <img src={iconsImages[6]} width="30"/>
            <strong>{txtSrc.services.sectionC.subA.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionC.subA.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[6]} width="30"/>
            <strong>{txtSrc.services.sectionC.subB.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionC.subB.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[6]} width="30"/>
            <strong>{txtSrc.services.sectionC.subC.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionC.subC.text[window.lang]}
            </li>
            <li>
            <img src={iconsImages[6]} width="30"/>
            <strong>{txtSrc.services.sectionC.subD.title[window.lang]}</strong><br/>
              {txtSrc.services.sectionC.subD.text[window.lang]}
            </li>
        </ol>
        <center>
            <h2 className="link" onClick={()=>{navigate("/contact")}}>
                {txtSrc.services.call[window.lang]}
            </h2>
            <Divider/>
        </center>
      </div>
    );
}

export default Services;
