// Divider.js
import dividerImg from "assets/images/divider.svg";
import "./Divider.css";

const Divider = ()=>{
    return (
        <img src={dividerImg} alt="divider" className="margin-20" style={{
            width:"300px",
            maxWidth:"90vw"
        }}/>
    )
}
export default Divider;

// end
