// Video.js
import { useState, useRef } from 'react';
import playImg from "assets/images/video/play.svg";
import "./Video.css";

// comps
const Poster = ({src, setShowVid, showreel})=>{
    return(
        <div className="relative full-width flex-col-center link" onClick={()=>{setShowVid(true)}}>
            <img src={src} alt="poster" className="full-width b-r-20"/>
            <img src={playImg} alt="play" className={"absolute grow "+(showreel?"":"side-play")} style={{
                width:"20%"
            }}/>
        </div>
    )
}
const Vid = ({src, posterPath})=>{
    const vidRef = useRef();
    const handlePlay = ()=>{
        const videoList = document.getElementsByTagName("video");
        for (let index = 0; index < videoList.length; index++) {
            const video = videoList[index];
            if( !video.isSameNode(vidRef.current) ) video.pause();
        }
    }
    return(
        <video ref={vidRef} poster={posterPath} src={src} controls className="full-width b-r-20" autoPlay onPlay={handlePlay}/>
    )
}

// main
const Video = ({name, showreel})=>{
    // inits
    const [showVid, setShowVid] = useState();
    const posterPath = '/media/images/posters/'+name+'.jpg';
    const vidPath = '/media/videos/'+name+'.mp4';

    // render
    return (
        <div className="relative flex-col-center full-width">
            { showVid?<Vid src={vidPath} posterPath={posterPath}/>:<Poster src={posterPath} setShowVid={setShowVid} showreel={showreel}/> }
        </div>
    )
}

export default Video;

// end
