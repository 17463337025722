// imports
import { useNavigate } from "react-router-dom";
import Contact from 'comps/Contact/Contact';
import Divider from 'comps/Divider/Divider';
import Video from 'comps/Video/Video';
import txtSrc from "assets/text.json";
import head1 from "assets/images/heads/1.webp";
import head2 from "assets/images/heads/4.webp";
import head3 from "assets/images/heads/2.webp";
import './Home.css';

// comps
const heads = [
    head1,
    head2,
    head3
]
const Title = ()=>{
    return(
        <div className='flex-col-center' style={{maxWidth:"95vw"}}>
            <h1 className='font-40 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"} style={{margin:"0px"}}>
                {txtSrc.home.titleA[window.lang]}
            </h1>
            <h2 className='font-30 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"} style={{margin:"10px"}}>
                {txtSrc.home.titleB[window.lang]}
            </h2>
        </div>
    )
}
const CfaButton = ()=>{
    const navigate = useNavigate();
    return(
        <div className="font-30 link grow text-center" dir={window.lang=="heb"?"rtl":"ltr"} style={{
            backgroundColor:"#1cd695",
            color:"#ffffff",
            borderRadius:"100px",
            padding: "20px 40px",
            fontWeight: "bold"
        }} onClick={()=>{navigate("/contact")}}>
            {txtSrc.home.buttonCFA[window.lang]}
        </div>
    )
}
const RightTitle = ()=>{
    return(
        <div className="flex-col-center padding-40" style={{
            backgroundColor:"#1d598e",
            width:"100vw"
        }}>
            <h3 className='font-30 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"} style={{
                margin:"0px"
            }}>
                {txtSrc.home.titleC[window.lang]}
            </h3>
            <div className="margin-10"/>
            <CfaButton/>
        </div>
    )
}
const Showreel = ()=>{
    return(
        <div className='relative' style={{
            width: "800px",
            maxWidth: "95vw"
        }}>
            <Video name="showreel" showreel={true}/>
        </div>
    )
}
const Logo = ({logo})=>{
    return <img className="brand_logo margin-10" alt="logo" src={"/media/images/logos/"+logo+".png"}/>
}
const LogosTitle = ()=>{
    return(
        <div className='font-40 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"}>
            {txtSrc.home.titleE[window.lang]}
        </div>
    )
}
const Brands = ()=>{
    const logosList = [
        "bezalel",
        "bpm",
        "cinema",
        "jeru",
        "keter",
        "mandel",
        "mccan",
        "netafim",
        "partner",
        // "pazgas",
        // "srut",
        "uni",
        // "yoha"
    ]
    
    return(
        <div className='flex-col-center margin-20' style={{
            width: "1000px",
            maxWidth: "95vw"
        }}>
            <LogosTitle/>
            <div className='flex-row-center flex-wrap margin-10'>
                {logosList.map(logo => <Logo logo={logo} key={logo}/>)}
            </div>
        </div>
    )
}
const Testi = ({testi, index})=>{
    return(
        <div className='padding-20 b-r-20 flex-col-center margin-20' style={{
            width:"300px",
            backgroundColor:"#1E598E"
        }}>
            <p className="padding-20 text-center b-r-20 font-15" style={{
                height:"180px",
                backgroundColor:"white"
            }}>
                {testi.text}
            </p>
            <h4 className="flex-row-center text-white" style={{
                margin:"0px",
                height:"80px"
            }}>
                <img src={heads[index]} className="b-r-100" style={{
                    width:"50px",
                    height:"50px"
                }}/>
                <div className="margin-10"/>
                {testi.title}
            </h4>
        </div>
    )
}
const Testis = ()=>{
    return(
        <div className='flex-col-center margin-20' style={{
            maxWidth: "95vw"
        }}>
            <div className='font-40 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"}>
                What people say about us:
            </div>
            <div className="flex-row-center flex-wrap">
                {txtSrc.testi.map((testi, index)=><Testi testi={testi} index={index} key={index}/>)}
            </div>
        </div>
    )
}
const Portfolio = ()=>{
    const navigate = useNavigate();
    return(
        <div className="font-40 link grow text-white margin-30 text-center" dir={window.lang=="heb"?"rtl":"ltr"} style={{
            backgroundColor:"#1cd695",
            borderRadius:"100px",
            padding: "20px 40px",
            fontWeight: "bold"
        }} onClick={()=>{navigate("/works")}}>
            {txtSrc.home.button[window.lang]}
        </div>
    )
}
const ContactMsg = ()=>{
    return(
        <div className='font-30 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"}>
            {txtSrc.home.contact[window.lang]}
        </div>
    )
}
// main
const Home = ()=>{
    // render
    return (
        <div className='flex-col-center'>
            <Title/>
            <Divider/>
            <Showreel/>
            <Divider/>
            <RightTitle/>
            {window.lang=="heb"?<Brands/>:<Brands/>}
            <Divider/>
            <Portfolio/>
            <Divider/>
            <ContactMsg/>
            <Contact/>
        </div>
    )
}

// export
export default Home;
