//import
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// funcs
const isLocal = ()=>{
    return (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === "0.0.0.0"
    );
}

// inits
const firebaseConfig = {
    apiKey: "AIzaSyDW6E-UKNENQNNvdbLcAZ7CbFJrjMUvsSc",
    authDomain: "db-studio-53c6a.firebaseapp.com",
    projectId: "db-studio-53c6a",
    storageBucket: "db-studio-53c6a.appspot.com",
    messagingSenderId: "710936711179",
    appId: "1:710936711179:web:5612eb8b078438f851f70e"
};
const firebaseApp = initializeApp(firebaseConfig);
if(isLocal()){
    window.dev = true;
    connectFunctionsEmulator(getFunctions(), "localhost", 3001);
}
window.lang = "eng";
if(window.location.hostname.includes("heb") || window.location.hostname.includes("il")) window.lang = "heb";
// window.lang = "heb";

//export
const Config = {
    firebaseApp: firebaseApp,
    domain: "dbanimations.com"
}
export default Config;