import Navbar from 'comps/Navbar/Navbar';
import Home from 'comps/Home/Home';
import Works from 'comps/Works/Works';
import About from 'comps/About/About';
import Services from 'comps/Services/Services';
import Contact from 'comps/Contact/Contact';
import Footer from 'comps/Footer/Footer';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

const App = ()=>{
    var path = window.location.pathname;
    if(path!=='/about' && path!=='/contact' && path!=='/') path = '/';
    path = (path==='/')?'home':path.split('/').pop();
    return (
        <Router>
            <link rel="stylesheet" type="text/css" href={"/css/fonts_"+window.lang+".css"} />
            <Navbar/>
            <div className="App">
                <Routes>
                  <Route path='/about' element={<About />} />
                  <Route path='/services' element={<Services />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/works' element={<Works />} />
                  <Route path='/' element={<Home />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
