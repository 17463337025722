import faceImg from "assets/images/footer/face.svg";
import instaImg from "assets/images/footer/insta.svg";
import linkImg from "assets/images/footer/linkedin.svg";
import './Footer.css';
const Icons = ()=>{
    return(
        <div className="flex-row-center">
            <img src={faceImg} className="link" width="50" alt="facebook" onClick={()=>{window.open("https://web.facebook.com/dbstudioanimation/","_blank")}}/>
            <div className="margin-10"/>
            <img src={instaImg} className="link" width="50" alt="instagram" onClick={()=>{window.open("https://www.instagram.com/db.studio.animation/","_blank")}}/>
            <div className="margin-10"/>
            <img src={linkImg} className="link" width="50" alt="instagram" onClick={()=>{window.open("https://www.linkedin.com/company/db-studio-animation","_blank")}}/>
        </div>
    )
}
function Footer() {
    const date = new Date();
    return (
        <div className='Footer flex-col-center'>
            <Icons/>
            <div className="margin-10"/>
            <div className='copy'>
                © db Studio {date.getFullYear()}
            </div>
        </div>
    );
}

export default Footer;
