import React from 'react';
import ReactDOM from 'react-dom/client';
import Config from "./Config";
import App from './App';
import "css/borders.css";
import "css/dimensions.css";
import "css/flex.css";
import "css/main.css";
import "css/positions.css";
import "css/special.css";
import "css/text.css";

// render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);