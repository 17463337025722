// Navbar.js
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Config from "Config";
import txtSrc from "assets/text.json";
import logoImg from "assets/images/general/logo.png";
import menuImg from "assets/images/navbar/menu-open.png";
import "./Navbar.css";

const LangBut = ({margin})=>{
    const handleClick = ()=>{
        if(window.lang=="eng") window.open("https://il."+Config.domain,"_self");
        else window.open("https://"+Config.domain,"_self");
    }
    return(
        <div className='link grow z-10 absolute padding-5 text-white blue-bg b-r-40 font-10' style={{
            top: margin+"px",
            right: margin+"px"
        }} onClick={handleClick}>
            {txtSrc.menu.langBut[window.lang]}
        </div>
    )
}
const Logo = ()=>{
    const navigate = useNavigate();
    return(
        <img src={logoImg} className="link" alt="logo" onClick={()=>{navigate("/")}} style={{width:"80px"}}/>
    )
}
const Buttons = ()=>{
    const ButtonsArray = ({setShowMenu})=>{
        const routes = [
            "home",
            "works",
            "services",
            "about",
            "contact"
        ]
        const buttons = []
        routes.forEach(route => {
            buttons.push({
                label: txtSrc.menu[route][window.lang],
                route: route
            })
        });
        const Button = ({button})=>{
            const location = useLocation();
            const navigate = useNavigate();
            const handleClick = ()=>{
                navigate(button.route);
                if(setShowMenu) setShowMenu(false);
            }
            return (
                <div
                    className={'menu-button '+((location.pathname.includes(button.route) || (button.route==="home" && location.pathname==="/")) && 'menu-button-selected')}
                    onClick={handleClick}
                >
                    {button.label.toUpperCase()}
                </div>
            );
        }
        return(
            <>
                {buttons.map(button=><Button button={button} key={button.route}/>)}
            </>
        )
    }
    const DesktopButtons = ()=>{
        return(
            <div className='relative desktop flex-row-center' dir={window.lang=="heb"?"rtl":"ltr"}>
                {window.lang=="heb"?<LangBut margin="-20"/>:""}
                <ButtonsArray/>
            </div>
        )
    }
    const MobileButtons = ()=>{
        const [showMenu, setShowMenu] = useState();
        const MenuButton = ()=>{
            return(
                <img src={menuImg} alt="menu" className="link" width="80" onClick={()=>{setShowMenu(!showMenu)}}/>
            )
        }
        const Buttons = ()=>{
            return(
                <div className='overlay flex-col-center'>
                    <div className='flex-col-center z-10'>
                        <ButtonsArray setShowMenu={setShowMenu}/>
                    </div>
                    <div className='local-overlay' style={{
                        backgroundColor:"#1d598e",
                        opacity: "0.95"
                    }} onClick={()=>{setShowMenu(false)}}/>
                </div>
            )
        }
        return(
            <div className='relative mobile flex-col-center'>
                {window.lang=="heb"?<LangBut margin="-10"/>:""}
                <MenuButton/>
                {showMenu?<Buttons/>:""}
            </div>
        )
    }
    return(
        <div className='relative flex-col-center'>
            <DesktopButtons/>
            <MobileButtons/>
        </div>
    )
}
const Navbar = ()=>{
    const location = useLocation();
    useEffect(()=>{window.scrollTo(0,0)},[location])
    return (
        <div className='Navbar flex-col-center'>
            <div className='flex-row space-between' style={{
                width:"800px",
                maxWidth:"90vw"
            }}>
                <Logo/>
                <Buttons/>
            </div>
        </div>
    )
}
export default Navbar;

// end
