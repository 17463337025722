// imports
import Contact from 'comps/Contact/Contact';
import Video from 'comps/Video/Video';
import posterImg from "assets/images/svg/1.svg";
import txtSrc from "assets/text.json";
import './Works.css';

// comps
const Title = ()=>{
    return(
        <div className='flex-col-center margin-20'>
            <h2 className='text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"} style={{
                margin:"0px"
            }}>
                {txtSrc.works.titleA[window.lang]}
            </h2>
            <div className='font-20 text-white text-center' dir={window.lang=="heb"?"rtl":"ltr"} style={{
                width: "700px",
                maxWidth: "90vw"
            }}>
                {txtSrc.works.titleB[window.lang]}
            </div>
        </div>        
    )
}
const Videos = ()=>{
    const moviesList = [
        'dynamo',
        'ritmo',
        'engage',
        'where_2',
        'jff_36',
        'jff_34',
        window.lang=="heb"?'partner':'power_dry',
        'netafim',
        'little_red',
        'fish_us',
        'exit',
        'photo_is'
    ];
    const descriptions = {}
    moviesList.forEach(movie => {
        descriptions[movie] = {
            title: txtSrc.works[movie].title[window.lang],
            description: txtSrc.works[movie].text[window.lang]
        }
    });
    const Vid = ({name})=>{
        const Desc = ()=>{
            return(
                <div className='flex-col margin-10'>
                    <div className='font-20 text-white' dir={window.lang=="heb"?"rtl":"ltr"}>
                        {descriptions[name].title}
                    </div>
                    <div className='font-15 text-white' dir={window.lang=="heb"?"rtl":"ltr"}>
                        {descriptions[name].description}
                    </div>
                </div>
            )
        }
        return(
            <div className='relative margin-10' style={{
                width: "400px",
                maxWidth: "90vw"
            }}>
                <Video name={name}/>
                <Desc/>
            </div>
        )
    }
    return(
        <div className='flex-row flex-wrap justify-center' style={{
            width: "1000px",
            maxWidth: "90vw"
        }}>
            {moviesList.map(name=><Vid name={name} key={name}/>)}
        </div>   
    )
}
const Cfa = ()=>{
    return(
        <div className='flex-col-center margin-20'>
            <div className='font-40 text-center text-white' dir={window.lang=="heb"?"rtl":"ltr"}>
                {txtSrc.works.titleC[window.lang]}
            </div>
            <div className='margin-5'/>
            <div className='font-25 text-center text-white' dir={window.lang=="heb"?"rtl":"ltr"}>
                {txtSrc.works.titleD[window.lang]}
            </div>
        </div>
    )
}

// main
const Works = ()=>{
    // render
    return (
        <div className="flex-col-center">
            <img src={posterImg} alt="works" style={{
                width:"300px",
                maxWidth:"50vw"
            }}/>
            <Title/>
            <Videos/>
            <Cfa/>
            <Contact/>
        </div>
    )
}

// export
export default Works;
